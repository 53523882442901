import React, { useState } from "react";
import Breadcrumb from "../../atoms/breadcrumb/breadcrumb";
import FormContactPre from "../form_preinscription/formPreinscription";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import { OptimizeImage } from "../../../shared/utils/stringUtilities";
import "./bodyContactPre.scss";

interface IBodyContact {
   nodes: {
      tituloPrincipal: string;
      descripcion: { json: any };
      tituloFormulario: string;
      descripcionFormulario: { json:any };
      tituloUbicacion: string;
      tituloBoton: string;
      redireccionBoton: string;
      referenciaUbicaciones:Array <{
         titulo:string;
         tituloUbicacion:string;
         localizacion: {
            lat: number,
            lon: number
         }
      }>;
      referenciaPreguntasFrecuentes: Array<ReferenciaContenido>;
      titulosFormulario: { json: any };
      imagenFormulario: any;
   };
}


const Text = (children:any, classname:string) => (
   <p className={classname}>{children}</p>
)

const Span = (children:any) => (<span>{children}</span>)

const options_subtitle = {
   renderNode: {
      [BLOCKS.PARAGRAPH]: (_node:any, children:any) => Text(children, 'o-contact-preinscripcion__header-subtitle')
   },
};

const options_message = {
   renderNode: {
      [BLOCKS.PARAGRAPH]: (_node:any, children:any) => Span(children)
   },
};

const BodyContactPre = (props:IBodyContact) => {
   const [isUser, setIsUser] = useState(false)

   return (
      <div className="o-contact-preinscripcion">
         <Breadcrumb content={`Inicio / ${props.nodes[0].tituloPrincipal}`} />

         <div className={`o-contact-preinscripcion__header`}>
            <h1 className="o-contact-preinscripcion__header-title title-primary">
               {props.nodes[0].tituloPrincipal}
            </h1>
            {props.nodes[0].descripcion.json.content && documentToReactComponents(props.nodes[0].descripcion.json, options_subtitle)}
            <p className="o-contact-preinscripcion__header-message">
               {props.nodes[0].tituloFormulario}
               {props.nodes[0].descripcionFormulario.json.content && documentToReactComponents(props.nodes[0].descripcionFormulario.json, options_message)}
            </p>
         </div>

         <div className={`o-contact-preinscripcion__cont`}>
            <div className="o-contact-preinscripcion__cont-form">
               <FormContactPre {...props.nodes[0].titulosFormulario} 
                  isUser={isUser} 
                  setIsUser={setIsUser}
                  image={props.nodes[0].imagenFormulario && OptimizeImage(props.nodes[0].imagenFormulario.file.url, props.nodes[0].imagenFormulario.file.contentType, 768)}
               /> 
            </div>
            {/* <div className={"o-contact-preinscripcion__cont-img hide-on-mobile"}>
               <img loading="lazy" 
                  src={props.nodes[0].imagenFormulario && OptimizeImage(props.nodes[0].imagenFormulario.file.url, props.nodes[0].imagenFormulario.file.contentType, 768)}
                  alt="Imagen contacto"/>
            </div> */}
         </div>
      </div> 
   );
};

export default BodyContactPre;
