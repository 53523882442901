import React from "react";
import Layout from "../components/organisms/layout/layout";
import SEO from "../shared/seo";
import BodyContactPre from "../components/organisms/body-contact-pre/bodyContactPre";
import { useStaticQuery, graphql } from "gatsby";

const PreInscriptionPage = () => {
   const data = useStaticQuery(graphql`
   query CONTACT_CONTENT_PRE {
      allContentfulContactenos(filter: {titulo: {eq: "Información formulario pre inscripción"}}) {
        nodes {
          tituloPrincipal
          descripcion {
            json
          }
          tituloFormulario
          descripcionFormulario {
            json
          }
          imagenFormulario {
            title
            file {
              url
            }
            fluid(maxWidth: 960, quality: 75) {
              src
              srcSet
              base64
              aspectRatio
              sizes
            }
          }
          titulosFormulario {
            tituloGeneral
            referenciaFormulario {
              tipoCampo
              inputPrincipal
              mensajeError
              listaSelect
              nombreId
              tituloBotonTerminos
              textoTerminos {
                json
              }
            }
          }
         }
      }
    }
   `);

   return (
      <Layout hideHeaderDesktop={true} hideHeaderMobile={true} isNotRoot={true}>
         <SEO title="Preinscipcion " />
         <BodyContactPre {...data.allContentfulContactenos} />
      </Layout>
   );
};

export default PreInscriptionPage;