import React, { useState, useEffect, useRef } from "react"
import "./inputFiles.scss"
import Check from "../../../assets/images/icons/check_green.svg"
import File from "../../../assets/images/icons/upload_file.svg"

const InputFiles = (props: InputFilesInterface) => {

   const {
      title,
      value,
      nameId,
      tabIndex,
      handleChange,
      maxFiles,
      binaries,
      validTypes
   } = props;

   const [filesArr, setFilesArr] = useState<any>(value || []);

   let inputRef = useRef(null);

   const toastMsgs = {
      default: "",
      errorFileSize: "El archivo debe pesar menos de 2MB",
      errorFileType: "El archivo debe tener el formato adecuado",
      successDelete: "El archivo se eliminó exitosamente"
   }

   const [toastMsg, setToastMsg] = useState<any>(toastMsgs.default);
   const [isFile, setisFile] = useState(false)

   const verifyType = (file: any): boolean => {
      if (validTypes) {
         for (let type of validTypes) {
            if ((type === "image" && file[0].type.includes("image")) ||
               (type === "png" && file[0].type.includes("png")) ||
               (type === "jpg" && file[0].type.includes("jpg")) ||
               (type === "jpeg" && file[0].type.includes("jpeg")) ||
               (type === "pdf" && file[0].type.includes("pdf")) ||
               (type === "doc" && file[0].name.includes("doc")) ||
               (type === "xls" && file[0].name.includes("xls"))) {
               return true
            }
         }
      } else if (file[0].type.includes("image") ||
         file[0].type.includes("pdf") ||
         file[0].name.includes("doc") ||
         file[0].name.includes("xls")) {
         return true
      }
      return false
   }

   useEffect(() => {
      setTimeout(() => {
         setToastMsg(toastMsgs.default);
      }, 4000);
   }, [toastMsg]);

   useEffect(() => {
      handleChange(filesArr, nameId);
   }, [filesArr]);

   const handleFileChange = (file: any) => { 
      if (filesArr.length == 2) {
         return;
      }
      if (file) {
         if (file[0].size < 2100000) {
            
            if (verifyType(file)) {
               setToastMsg(toastMsgs.default);
               filesArr && setFilesArr(filesArr => [...filesArr, file]);
               setisFile(true)
            } else {
               setToastMsg(toastMsgs.errorFileType);
            }
         }
         else {
            setToastMsg(toastMsgs.errorFileSize);
         }
      }
   }

   const handleRemoveFile = (fileName: any) => {
      filesArr && setFilesArr(filesArr.filter(item => item[0].name != fileName));
      binaries && handleChange([], "binaries")
      setToastMsg(toastMsgs.successDelete);
   }

   return (
      <div className="a-input-files">
         {
            (!isFile || (maxFiles && filesArr.length < maxFiles) ) &&
            <>
               <label htmlFor="avatar" className="a-input-files__title">
                  {title}
               </label>
               <div className="a-input-files__input">
                  <div>
                     <img src={File} alt="Upload File" />
                     <input
                        onChange={e => { handleFileChange(e.target.files) }}
                        type="file"
                        id="avatar" name="avatar"
                        tabIndex={tabIndex}
                        ref={inputRef}
                        // accept="image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                     />
                  </div>
                  <span>Máximo 2MB</span>
               </div>
            </>
         }


         { toastMsg &&
            <div className="a-toast-msg">
               <p> {toastMsg} </p>
            </div>
         }

         <div className="a-input-files__files">
            <p className="a-input-files__files-title">Archivos subidos </p>
            <ul className="a-input-files__files-list">
               {filesArr.map((item, i) => (
                  <li key={`item${i}`}>
                     <img src={Check} alt="Check green" />
                     <p> {item[0].name} </p>
                     <button
                        onClick={e => { e.preventDefault(); handleRemoveFile(item[0].name); !filesArr.length && setisFile(false) }}>
                        <i className="icon-trash-alt"></i>
                     </button>
                  </li>
               ))}
            </ul>
         </div>
      </div>
   )
}

interface InputFilesInterface {
   title?: string,
   nameId: string,
   tabIndex?: number,
   value: any,
   handleChange: (value: any, name: string) => any,
   maxFiles?: number,
   binaries?: any,
   validTypes?: any[]
}

export default InputFiles